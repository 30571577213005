@charset "utf-8";
@import "variables";

@media screen and (min-width:1440px) and (min-height:850px){
  .button{
    height:73px;
    padding:0 40px 4px;
    margin-top:43px;
    margin-left:-7px;
    font-size:26px;
    line-height:28px;
    border-radius:38px;


    i{
      height:73px;
      top:4px;
      border-radius:38px;
    }

    &.collapsed{
      i{
        width:73px;
        left:0;
        transition:width 1s $easeInOut;
      }
    }
  }

  .index-page{
    .wrapper{
      padding:100px 0;
    }
  }

  .index-space{
    height:77px;
    display:block;
  }




  .form-page{
    .wrapper{
      padding:100px 0;
    }
  }

  .form-back{
    width:131px;
    height:86px;
  }

  .input-wrapper{
    margin-top:17px;

    input{
      height:70px;
      padding:16px 40px 20px;
      font-size:18px;
      line-height:22px;
    }
  }
  .input-wrapper-top{
    padding:0 40px;
  }


  .checkbox-wrapper{
    margin-top:30px;

    input{
      width:70px;
      height:70px;
    }
  }

  .checkbox-image{
    width:70px;
    height:70px;
  }
  .checkbox-label{
    margin-left:15px;
    font-size:20px;
    line-height:22px;
  }

}