@font-face{
  font-family:'Druk Wide Cyr';
  src:url('../fonts/DrukWideCyr-Medium.eot');
  src:local('Druk Wide Cyr Medium'), local('DrukWideCyr-Medium'),
      url('../fonts/DrukWideCyr-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/DrukWideCyr-Medium.woff2') format('woff2'),
      url('../fonts/DrukWideCyr-Medium.woff') format('woff');
  font-weight:500;
  font-style:normal;
  font-display:swap;
}

@font-face{
  font-family:'Druk Wide Cyr';
  src:url('../fonts/DrukWideCyr-Bold.eot');
  src:local('Druk Wide Cyr Bold'), local('DrukWideCyr-Bold'),
      url('../fonts/DrukWideCyr-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/DrukWideCyr-Bold.woff2') format('woff2'),
      url('../fonts/DrukWideCyr-Bold.woff') format('woff');
  font-weight:bold;
  font-style:normal;
  font-display:swap;
}